import axios from '@axios';

export function login(data) {
    return axios.post('/auth/local', data);
}

export function forgotPassword(data) {
    return axios.post('/auth/forgot-password', data);
}

export function resetPassword(data) {
    return axios.post('/auth/reset-password', data);
}
