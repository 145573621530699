<template>
    <v-card flat>
        <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">{{ $t('forgotPassword.title') }}</p>
            <p class="mb-2">{{ $t('forgotPassword.subtitle') }}</p>
        </v-card-text>
        <v-card-text>
            <ValidationObserver v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(forgotPassword)">
                    <ValidationProvider ref="providerIdentifier" :name="$t('forgotPassword.fields.email')" rules="email|required" v-slot="{ errors }">
                        <v-text-field
                            v-model="form.email"
                            outlined
                            :success-messages="successMessages"
                            :label="$t('forgotPassword.fields.email')"
                            placeholder="john@example.com"
                            :error-messages="errors"
                            hide-details="auto"
                            class="mb-4"></v-text-field>
                    </ValidationProvider>
                    <v-btn :disabled="isDisabled" :loading="isLoading" type="submit" block color="primary">
                        {{ !isDisabled ? $t('forgotPassword.fields.button') : $t('forgotPassword.tryAgain', [currentSeconds]) }}
                    </v-btn>
                </v-form>
            </ValidationObserver>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
            <router-link :to="{ name: 'Login' }" class="d-flex align-center text-sm">
                <v-icon size="24" color="primary">
                    {{ icons.mdiChevronLeft }}
                </v-icon>
                <span>{{ $t('forgotPassword.backLogin') }}</span>
            </router-link>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { forgotPassword } from '@/api/auth';
    import { mdiChevronLeft } from '@mdi/js';
    import { ref } from '@vue/composition-api';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    export default {
        setup() {
            const isPasswordVisible = ref(false);
            return {
                isPasswordVisible,
                icons: {
                    mdiChevronLeft,
                },
            };
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
                isLoading: false,
                isDisabled: false,
                defaultSeconds: 59,
                currentSeconds: this.defaultSeconds,
                interval: null,
                successMessages: '',
                form: {
                    email: 'danielcarneiro@iotech.pt',
                },
            };
        },
        methods: {
            async forgotPassword() {
                this.isLoading = true;
                await forgotPassword(this.form)
                    .then(() => {
                        this.successMessages = this.$t('forgotPassword.api.success');
                        this.isDisabled = true;
                        this.startTimer();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$refs.providerIdentifier.applyResult({
                            errors: [this.$t(`shared.commonError`)],
                            valid: false,
                            failedRules: {},
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            startTimer() {
                this.currentSeconds = this.defaultSeconds;
                this.interval = setInterval(() => {
                    if (this.currentSeconds > 1) {
                        this.currentSeconds--;
                    } else {
                        this.isDisabled = false;
                        clearInterval(this.interval);
                    }
                }, 1000);
            },
        },
    };
</script>

<style lang="scss">
    @import '@core/preset/preset/pages/auth.scss';
</style>
